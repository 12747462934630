import * as React from 'react'
import { IPageSectionVisualizer } from 'interfaces/page-section-visualizer'
import { IUiResources } from 'interfaces/ui-resource'
import { getTotalPlasticSavings } from '@/lib/util/environmental-impact'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import Link from 'next/link'
import styles from './style.module.css'
import { useCustomerContext } from 'context/customer-context'
import { useFeature } from '@growthbook/growthbook-react'
import { plasticCalculatorCTAFeatureName } from 'constants/growthbook'

interface IProps {
    section: IPageSectionVisualizer
    uiResources?: IUiResources
    className?: string
}

const titleRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <h2 className="text-3xl font-semibold leading-120% tracking-tight">{children}</h2>
    ),
}

const subHeaderRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="max-w-sm font-medium leading-120% tracking-tight lg:text-base">{children}</p>
    ),
}

export const VisualizerPlasticCalculatorSection = ({ section, uiResources }: IProps): React.ReactElement => {
    const [numberOfPrescriptions, setnumberOfPrescriptions] = React.useState(1)
    const [age, setAge] = React.useState(0)
    const estimatedLifespan = 95
    const { track } = useCustomerContext()

    const plasticCalculatorCTAFeature = useFeature(plasticCalculatorCTAFeatureName)
    const plasticCalculatorCTAFeatureVariation = plasticCalculatorCTAFeature.value ?? section.ctaLink.href

    React.useEffect(() => {
        track('Plastic Calculator - View')
    }, [track])

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setnumberOfPrescriptions(Number(event.target.value))
    }

    const handleAgeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAge(Number(event.target.value))
    }

    const handleCtaClick = () => {
        // Track CTA click through event
        track('Plastic Calculator - CTA Click', { age, numberOfPrescriptions })
    }

    const calculatePlasticFootprint = () => {
        const totalSavings = getTotalPlasticSavings(
            age,
            numberOfPrescriptions,
            numberOfPrescriptions,
            estimatedLifespan,
        )
        return `${totalSavings.toFixed(2)} ${uiResources['plasticCalculatorLbsOfPlasticLabel'].value}`
    }

    if (!section) {
        return null
    }

    const { richTitle, subHeader, ctaLink } = section
    const isAgeandRxSelected = age > 0 && numberOfPrescriptions > 0

    return (
        <div className="section--v2">
            <div className="bg-gradient-to-t from-white to-painFever/20 grid sm:grid-cols-2 gap-x-8 p-12 rounded-lg shadow-lg gap-y-10 sm:gap-y-0 items-center min-h-[600px] sm:min-h-[340px]">
                <div className="w-full mx-auto flex justify-between text-center">
                    <div className="flex items-center flex-col gap-y-6">
                        {richTitle?.json && (
                            <ContentfulRichText
                                renderNode={titleRenderNode}
                                content={richTitle.json}
                                ignoreMarkdownStyles
                            />
                        )}
                        {subHeader?.json && (
                            <ContentfulRichText
                                renderNode={subHeaderRenderNode}
                                content={subHeader.json}
                                className="text-base"
                                ignoreMarkdownStyles
                            />
                        )}
                        {ctaLink && (
                            <Link
                                className={`px-6 rounded-md uppercase bg-painFever border-white font-bold text-white py-3 hidden sm:block ${
                                    isAgeandRxSelected ? '' : 'invisible'
                                }`}
                                href={plasticCalculatorCTAFeatureVariation}
                                onClick={handleCtaClick}
                            >
                                {ctaLink.name}
                            </Link>
                        )}
                    </div>
                </div>
                <div className="flex items-center flex-col">
                    <label
                        htmlFor="minmax-range"
                        className="block mb-2 text-sm sm:text-base text-black font-semibold text-center"
                    >
                        {uiResources['plasticCalculatorRxLabel'].value}
                    </label>
                    <p className="text-center mb-2 font-bold text-lg">{numberOfPrescriptions}</p>

                    {/* Prescriptions Slider */}
                    <div className="w-full">
                        <input
                            id="minmax-range"
                            type="range"
                            min="1"
                            max="10"
                            value={numberOfPrescriptions}
                            onChange={handleSliderChange}
                            className={`w-full h-2.5 mb-6 bg-white/90 border-[1px] border-gray rounded-lg cursor-pointer appearance-none ${styles.sliderThumb}`}
                        />
                        <div className="flex w-full justify-between -mt-4 text-gray-darker">
                            <span>1</span>
                            <span>10</span>
                        </div>
                    </div>

                    {/* Age */}
                    <div className="flex flex-col items-center">
                        <p className="text-sm sm:text-base text-center font-semibold">
                            {uiResources['plasticCalculatorAgeLabel'].value}
                        </p>
                        <input
                            type="number"
                            className="sm:w-20 w-16 h-10 px-3 rounded-lg border-[1px] border-gray mt-2 text-center"
                            placeholder="Age"
                            min="0"
                            max="94"
                            value={age === 0 ? '' : age > 95 ? 95 : age}
                            onChange={handleAgeChange}
                        />
                        {/* Result */}
                        {isAgeandRxSelected ? (
                            <p className="font-semibold text-center text-2xl mt-6">{calculatePlasticFootprint()}</p>
                        ) : (
                            <p className="font-medium text-center text-gray text-sm mt-4">
                                {uiResources['plasticCalculatorErrorLabel'].value}
                            </p>
                        )}
                        {ctaLink && (
                            <Link
                                className={`px-6 rounded-md uppercase bg-painFever border-white font-bold text-white py-3 mt-6 sm:hidden ${
                                    isAgeandRxSelected ? '' : 'invisible'
                                }`}
                                href={ctaLink.href}
                                onClick={handleCtaClick}
                            >
                                {ctaLink.name}
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
