import React from 'react'
import ContentfulRichText from 'components/rich-text'
import { IPageSectionForm } from 'interfaces/page-section-form'
import { PersonalizedBottleCap } from 'svgs/personalized-bottle-cap'
import { BLOCKS } from '@contentful/rich-text-types'
import { getPersonalizedTokenName } from 'lib/util/misc'
import Button from 'components/buttons/button'
import { onboardingFlowFullCapNameQueryParamName, tokenSectionBackgroundImage } from 'misc-variables'
import { RenderNode } from '@contentful/rich-text-react-renderer'

interface IProps {
    section: IPageSectionForm
    className?: string
}

export const RxTokenFormSection = ({ section, className = '' }: IProps): React.ReactElement => {
    const [name, setName] = React.useState('')

    if (!section) {
        return null
    }

    const { inputsCollection, header, submitButtonText, action } = section
    const nameInput = inputsCollection.items[0]

    const [firstName, restOfTheName] = getPersonalizedTokenName(name || nameInput.placeholder)

    const headerRenderNode: RenderNode = {
        [BLOCKS.PARAGRAPH]: (_node, children) => (
            <p className="text-center lg:text-left text-3xl sm:text-5xl sm:leading-10 tracking-tighter max-w-sm">
                {children}
            </p>
        ),
    }

    return (
        <section className={`section--v2 ${className}`}>
            <div className="flex flex-col lg:flex-row bg-blue-rx rounded-4xl overflow-hidden lg:max-h-[550px]">
                <div className="lg:w-[55%] flex justify-center items-center lg:justify-end">
                    <div className="w-full max-w-2xl">
                        <PersonalizedBottleCap
                            firstName={firstName || undefined}
                            lastName={firstName ? restOfTheName ?? '' : undefined}
                            className="scale-150 -mt-9"
                            tokenImage={tokenSectionBackgroundImage}
                        />
                    </div>
                </div>
                <div className="relative lg:w-[45%] z-10 flex justify-center items-center lg:justify-start p-5 pb-10 lg:pr-10 lg:pl-0 lg:py-5">
                    <div className="max-w-md">
                        {header?.json && <ContentfulRichText content={header.json} renderNode={headerRenderNode} />}
                        <form action={action}>
                            <input
                                name={onboardingFlowFullCapNameQueryParamName}
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value)
                                }}
                                className="text-center lg:text-left font-medium mt-6 lg:mt-10 min-w-[300px] rounded-2lg px-4 py-2 bg-white shadow-[0px_0px_10px_3px_rgba(0,0,0,0.15)]"
                                placeholder={nameInput.placeholder}
                                data-cy="bottle-cap-name-input"
                            />
                            <div className="flex justify-center lg:justify-start mt-6 lg:mt-4">
                                <Button
                                    className="flex items-center justify-center gap-x-2 min-w-52 h-9 py-5 mt-1 text-sm border-black bg-black font-light"
                                    disabled={!name.trim()}
                                >
                                    {submitButtonText}
                                </Button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}
